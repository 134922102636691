<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section title class="text-xl">
        {{ $t("finalizeInquiryDialog.title") }}
      </q-card-section>
      <q-card-section>
        {{ $t("finalizeInquiryDialog.confirmation") }}
      </q-card-section>

      <q-card-section v-if="errorMessage !== null">
        <q-banner rounded dense class="bg-negative text-white q-mb-md">
          {{ errorMessage }}
          <template v-slot:action>
            <q-btn
              flat
              dense
              color="white"
              :label="$t('Dismiss')"
              @click="errorMessage = null"
            />
          </template>
        </q-banner>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          dense
          flat
          :label="$t('finalizeInquiryDialog.cancel')"
          @click="onDialogCancel"
        />
        <q-btn
          dense
          color="primary"
          :label="$t('finalizeInquiryDialog.ok')"
          :disable="isLoading || !inquiry"
          @click="handleOkClick"
        />
      </q-card-actions>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getFinalizationErrorMessage } from "@/utils/errors";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const q = useQuasar();
const { t } = useI18n();

const { inboxId } = useRouteParams();
const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);
const { loadData } = useCurrentInquiryStore();

const isLoading = ref(false);

const errorMessage = ref<string | null>(null);

onMounted(async () => {
  isLoading.value = true;
  try {
    await loadData();
  } catch (e) {
    onDialogCancel();
    console.error(e);
  } finally {
    isLoading.value = false;
  }
});

function handleOkClick() {
  if (!inquiry.value) return;
  if (inquiry.value.progress.completed < inquiry.value.progress.offered) {
    q.dialog({
      title: t("finalizeInquiryDialog.inquiryNotCompleteDialog.title"),
      message: t("finalizeInquiryDialog.inquiryNotCompleteDialog.message"),
      ok: {
        dense: true,
        flat: true,
        label: t("finalizeInquiryDialog.inquiryNotCompleteDialog.ok"),
      },
      cancel: {
        dense: true,
        primary: true,
        label: t("finalizeInquiryDialog.inquiryNotCompleteDialog.cancel"),
      },
      persistent: true,
    }).onOk(() => {
      finalizeInquiry();
    });
  } else {
    finalizeInquiry();
  }
}

async function finalizeInquiry() {
  if (!inquiry.value) return;

  errorMessage.value = null;

  isLoading.value = true;
  try {
    await api.finalizeInquiry(inboxId.value, inquiry.value.id, {
      ignoreErrors: [400],
    });
    if (inquiry.value)
      inquiry.value.status = {
        id: "FINALIZED",
        name: "FINALIZED",
        originalStatus: "FINALIZED",
        isSelectable: false,
        color: "positive",
        custom: false,
      };
    onDialogOK();
  } catch (e: any) {
    if (e.response?.data?.reasons) {
      errorMessage.value = getFinalizationErrorMessage(
        e.response.data.reasons,
        inquiry.value
      );
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
