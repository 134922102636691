<template>
  <q-menu context-menu>
    <q-list dense>
      <q-item
        clickable
        v-close-popup
        :disable="
          !clickedGroupId || disabled || !clickedGroup?.offerPositions.length
        "
        @click="$emit('update:copy-products-source-group-id', clickedGroupId)"
      >
        <q-item-section>
          <div class="row no-wrap items-center gap-sm">
            <q-icon :name="icons.copy" color="neutral-7" />
            {{ $t("inquiryPositionsPage.offerPositionGroup.copyProducts") }}
          </div>
        </q-item-section>
      </q-item>
      <q-item
        clickable
        v-close-popup
        :disable="!copyProductsSourceGroupId || disabled"
        @click="pasteProducts"
      >
        <q-item-section>
          <div class="row no-wrap items-center gap-sm">
            <q-icon :name="icons.paste" color="neutral-7" />
            {{ $t("inquiryPositionsPage.offerPositionGroup.pasteProducts") }}
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import icons from "@/config/icons";

const props = defineProps<{
  disabled: boolean;
  selectedGroupIds: number[];
  clickedGroupId: number | null;
  copyProductsSourceGroupId: number | null;
}>();

defineEmits<{
  "update:copy-products-source-group-id": [groupId: number | null];
}>();

const opgStore = useCurrentOfferPositionGroupsStore();

const { allOfferPositionGroups } = storeToRefs(opgStore);

const sourceGroup = computed(() => {
  return allOfferPositionGroups.value?.find(
    (group) => group.id === props.copyProductsSourceGroupId
  );
});

const clickedGroup = computed(() => {
  return allOfferPositionGroups.value?.find(
    (group) => group.id === props.clickedGroupId
  );
});

const q = useQuasar();
const { t } = useI18n();

async function pasteProducts() {
  if (!clickedGroup.value || !sourceGroup.value)
    throw new Error("Group not found");

  if (clickedGroup.value.offerPositions.length == 0) {
    await doCopyProducts();
    return;
  }

  q.dialog({
    title: t("inquiryPositionsPage.offerPositionGroup.pasteProducts"),
    message: t(
      "inquiryPositionsPage.offerPositionGroup.pasteProductsConfirmation"
    ),
    ok: {
      dense: true,
      label: t("inquiryPositionsPage.offerPositionGroup.pasteProducts"),
      color: "primary",
    },
    cancel: {
      dense: true,
      flat: true,
      label: t("inquiryPositionsPage.offerPositionGroup.cancel"),
      color: "neutral-7",
    },
  }).onOk(async () => {
    await doCopyProducts();
  });
}

async function doCopyProducts() {
  if (!clickedGroup.value || !sourceGroup.value)
    throw new Error("Group not found");

  q.loading.show({ delay: 400 }); // only show if loading takes longer than 400ms
  try {
    await opgStore.copyProducts(sourceGroup.value.id, clickedGroup.value.id);
  } finally {
    q.loading.hide();
  }
}
</script>
