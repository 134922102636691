import { i18n } from "@/i18n";

import type { Inquiry } from "@/types/inquiry";

export function getFinalizationErrorMessage(
  reasons: string[],
  inquiry: Inquiry
): string {
  return reasons
    .map((reason: string) =>
      i18n.global.t(`finalizeInquiryDialog.errorMessages.${reason}`, {
        customFieldNames: inquiry?.customFields
          .filter((field) => field.value === null)
          .map((field) => field.displayName)
          .join(", "),
      })
    )
    .join("\n");
}
