<template>
  <div class="column no-wrap gap-y-sm full-width">
    <div
      v-for="field in inquiry?.customFields"
      :key="field.id"
      class="row no-wrap gap-x-md justify-stretch full-width"
    >
      <q-input
        v-if="!field.choices?.length"
        v-model="field.value"
        dense
        outlined
        class="full-width"
        :label="field.displayName"
        :disable="!canEditInquiry"
        @blur="updateCustomField(field)"
      />
      <q-select
        v-else
        v-model="field.value"
        dense
        outlined
        class="full-width"
        :label="field.displayName"
        :options="field.choices"
        emit-value
        map-options
        :disable="!canEditInquiry"
        @update:model-value="updateCustomField(field)"
      />
      <cannot-edit-tooltip
        :inquiry="inquiry"
        :canEditInquiry="canEditInquiry"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import CannotEditTooltip from "./CannotEditTooltip.vue";
import type { Inquiry, InquiryCustomField } from "@/types/inquiry";

const store = useCurrentInquiryStore();
const { inquiry, canEditInquiry } = storeToRefs(store);
const { updateInquiry } = store;

async function updateCustomField(field: InquiryCustomField) {
  if (!inquiry.value) return;

  await updateInquiry({
    customFields: [
      {
        id: field.id,
        value: field.value,
      },
    ],
  } as Partial<Inquiry>);
}
</script>
