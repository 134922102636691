<template>
  <q-menu
    v-model="isOpen"
    v-bind="$attrs"
    ref="menuRef"
    no-parent-event
    @mouseenter="handleMenuMouseEnter"
    @mouseleave="handleMenuMouseLeave"
  >
    <slot />
  </q-menu>
</template>

<script setup lang="ts">
import type { QMenu } from "quasar";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    showDelay?: number;
    hideDelay?: number;
  }>(),
  {
    showDelay: 500,
    hideDelay: 500,
  }
);

const menuRef = ref<QMenu | null>(null);

const isParentMouseOver = ref(false);
const isMenuMouseOver = ref(false);

const isAnyMouseOver = computed(
  () => isParentMouseOver.value || isMenuMouseOver.value
);

const isOpen = ref(false);

const closeTimeout = ref<number | null>(null);
const showTimeout = ref<number | null>(null);

watch(isAnyMouseOver, (newValue) => {
  if (newValue) {
    if (closeTimeout.value) {
      clearTimeout(closeTimeout.value);
    }
    showTimeout.value = setTimeout(() => {
      isOpen.value = true;
    }, props.showDelay) as unknown as number; // setTimeout returns a number in the browser
  } else {
    if (showTimeout.value) {
      clearTimeout(showTimeout.value);
    }
    closeTimeout.value = setTimeout(() => {
      isOpen.value = false;
    }, props.hideDelay) as unknown as number; // setTimeout returns a number in the browser
  }
});

function handleParentMouseEnter() {
  isParentMouseOver.value = true;
}

function handleParentMouseLeave() {
  isParentMouseOver.value = false;
}

function handleMenuMouseEnter() {
  isMenuMouseOver.value = true;
}

function handleMenuMouseLeave() {
  isMenuMouseOver.value = false;
}

onMounted(() => {
  const parentEl = menuRef.value?.$el.parentElement;
  if (!parentEl) throw new Error("Parent element not found");

  parentEl.addEventListener("mouseenter", handleParentMouseEnter);
  parentEl.addEventListener("mouseleave", handleParentMouseLeave);
});

onBeforeUnmount(() => {
  const parentEl = menuRef.value?.$el.parentElement;
  if (!parentEl) return;

  parentEl.removeEventListener("mouseenter", handleParentMouseEnter);
  parentEl.removeEventListener("mouseleave", handleParentMouseLeave);
});
</script>
